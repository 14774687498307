.input-image-wrapper {
  position: relative;
  width: auto;
  height: 120px;
  margin: auto;
  border: 1px solid #ccc;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  &.eclipse {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  img {
    display: block;
    width: auto;
    height: 100%;
    // min-width: 100%;
    min-height: 100%;
  }
  input {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
