.auth-container {
  background-color: #e9ecef;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  form {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 4px;
    width: 320px;
  }

  button {
    width: 100%;
  }
}
