// @media (max-width: 1279px) {}

.web-container {
  position: relative;

  .btn {
    border-radius: 6px;
    font-size: 16px;
    padding: 12px 16px;
    font-weight: 600;

    &-danger {
      background: linear-gradient(89.87deg, #ed3237 0.98%, #d71b20 98.72%);
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-top: 2rem;
    color: #757575;

    @media (max-width: 767px) {
      flex-direction: column;
      row-gap: 1rem;
    }

    select {
      padding: 7px;
      border-color: #b11f24;
      color: #b11f24;
      border-radius: 5px;
      background-image: url('../../public/assets/images/red-arrow-down.png');
      background-repeat: no-repeat;
      background-position: 80% center;
      width: 60px;
      appearance: none;
      -webkit-appearance: none;
    }

    .total {
      flex-grow: 1;
      text-align: right;
    }

    button {
      background-color: transparent;
      border-color: transparent;
      cursor: pointer;
      color: #757575;
    }

    .numbers {
      display: flex;
      column-gap: 1rem;
      button {
        border: solid 1px #b11f24;
        border-radius: 4px;
        padding: 7px 12px;
        display: inline-block;
        transition: all 0.2s;

        &:hover,
        &.active {
          background-color: #b11f24;
          color: #fff;
        }

        &.active {
          cursor: default;
        }
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }

  .gallery {
    .thumbnail {
      .swiper-slide,
      swiper-slide {
        cursor: pointer;
      }
    }
    .modal {
      .swiper-slide,
      swiper-slide {
        text-align: center;

        img {
          width: 100%;
        }
      }

      &-title {
        flex-grow: 1;
        font-weight: 700;
        font-size: 24px !important;
        color: #000;
      }

      &-header {
        border-bottom: none;
        text-align: center;
      }

      &-footer {
        justify-content: center;
        border-top: none;
      }
    }
  }

  input.search {
    background-image: url('../../public/assets/images/icon-search.png');
    background-repeat: no-repeat;
    background-position: 96% center;
  }

  .scroll-to-top {
    border: none;
    width: 48px;
    height: 48px;
    background-color: #ffcc29;
    border-radius: 50%;
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 999;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  // HEADER
  header {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 575px) {
        padding: 0 2rem;
      }
    }

    padding: 0.5rem 0;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.4);

    position: fixed;
    top: 0;
    z-index: 2;
    background-color: #fff;
    width: 100%;

    .toggle-nav {
      display: none;
      @media (max-width: 1279px) {
        display: inline-block;
      }
    }

    & + div {
      margin-top: 81px;
    }

    .logo {
      height: 65px;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      column-gap: 2rem;
      transition: all 0.2s;
    }

    .desktop {
      @media (max-width: 1279px) {
        display: none;
      }
    }

    .mobile {
      display: none;
      @media (max-width: 1279px) {
        display: block;
      }
    }

    .mobile ul {
      height: 0;
      overflow: hidden;
      background-color: rgba($color: #ed3237, $alpha: 0.95);
      position: fixed;
      flex-direction: column;
      right: 0;
      top: 81px;
      width: 75%;
      z-index: 99999;

      a {
        color: #fff;
        display: inline-block;
        padding: 1rem;
        width: 100%;
        font-size: 18px;
      }

      &.show {
        height: calc(100vh);
      }
    }

    a {
      text-decoration: none;
      font-weight: 700;
      font-size: 12px;
      color: #626262;
    }

    a:hover,
    a.active {
      color: #ed3237;

      @media (max-width: 1279px) {
        color: #ffc107;
      }
    }
  }

  // FOOTER
  footer {
    background-color: #240708;
    color: #fff;

    & > p {
      font-weight: 400;
      font-size: 11px;
      text-align: center;
      padding: 1rem 0;
      margin-bottom: 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      padding-top: 2rem;

      @media (max-width: 1279px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }

    .left {
      display: flex;

      align-items: flex-start;
      column-gap: 1rem;

      & > img {
        height: 95px;
      }

      h3 {
        font-weight: 700;
        font-size: 14px;
      }

      p,
      span {
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .text-icon {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
      }
    }

    .mid {
      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        display: flex;

        li {
          padding: 0 1rem;
          text-align: center;
          border-right: #a78f90 solid 1px;

          &:last-of-type {
            border-right: none;
          }
        }

        @media (max-width: 575px) {
          flex-direction: column;

          li {
            padding: 0.5rem;
            border-right: none;
            border-bottom: #a78f90 solid 1px;
          }
        }
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        color: #a78f90;
      }
    }

    .right {
      display: flex;
      column-gap: 1rem;
    }
  }

  // SECTION
  section {
    padding: 3rem 0;

    h2 {
      font-weight: 700;
      font-size: 28px;
      text-align: center;
      margin-bottom: 2rem;

      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
  }

  // HOME
  .home {
    section {
      &.welcome {
        & > div {
          display: flex;
          column-gap: 1.5rem;
          justify-content: center;
          align-items: center;

          img {
            max-width: 320px;
          }

          div {
            width: 735px;

            @media (max-width: 767px) {
              width: 90%;
            }

            p {
              font-weight: 600;
              font-size: 14px;
            }

            p i {
              font-weight: 400;
              font-size: 16px;
              color: #4d4d4d;
            }
          }

          @media (max-width: 1279px) {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
          }
        }
      }

      &.event {
        background-image: url('../../public/assets/images/bg-event.jpg');
        position: relative;

        .event-calendar {
          width: 680px;
          background-color: #fff;
          border-radius: 5px;

          @media (max-width: 767px) {
            width: 90%;
          }

          & + div {
            @media (max-width: 767px) {
              width: 90%;
            }
          }

          .head {
            padding: 1rem;
            background-color: #806615;
            color: #fff;
            text-align: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            h4 {
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 0;
              display: inline-block;
            }
          }

          .body {
            .days {
              display: flex;
              padding: 1rem 0;
              margin-bottom: 0;

              .day {
                width: 14.28%;
                text-align: center;
              }

              span {
                font-weight: 600;
                font-size: 14px;

                @media (max-width: 575px) {
                  font-size: 12px;
                }
              }
            }

            .dates {
              display: flex;
              flex-wrap: wrap;

              .date {
                width: 14.28%;
                text-align: center;
                padding: 0.5rem 0;
              }

              span {
                font-weight: 500;
                font-size: 14px;

                &.active {
                  display: inline-block;
                  background-color: #ffcc29;
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  border-radius: 50%;
                }
              }
            }
          }
        }

        h2 {
          position: relative;
          z-index: 1;
        }

        & > div {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          column-gap: 3rem;
          position: relative;
          z-index: 1;

          @media (max-width: 1279px) {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
          }

          h3 {
            font-weight: 600;
            font-size: 20px;
          }

          ul {
            list-style-type: none;
            padding-left: 0;

            .event-date {
              display: inline-block;
              width: 110px;
              font-size: 16px;
              color: #4d4d4d;
            }

            .event-name {
              font-size: 16px;
              font-weight: 700;
              color: #806615;
            }
          }
        }

        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #f5f2e6, $alpha: 0.8);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.karate {
        .head {
          list-style-type: none;
          padding-left: 0;
          display: flex;
          width: 889px;
          margin: 0 auto;

          @media (max-width: 991px) {
            width: 90%;
          }

          li {
            flex-basis: 33.33%;
            text-align: center;
            span {
              display: inline-block;
              padding: 1rem;
              width: 100%;
              font-weight: 600;
              font-size: 24px;
              color: #c4c4c4;
              border-bottom: 3px solid #dddddd;
              cursor: pointer;

              @media (max-width: 991px) {
                font-size: 18px;
              }

              &:hover,
              &.active {
                color: #ed3237;
                border-bottom: 3px solid #ed3237;
              }
            }
          }
        }

        .container {
          .swiper {
            width: 100%;
            height: auto;
            padding: 0 2rem 2rem;
          }

          .swiper-button-next.swiper-button-disabled,
          .swiper-button-prev.swiper-button-disabled {
            color: #bbb;
          }

          .swiper-button-next.swiper-button-disabled,
          .swiper-button-prev.swiper-button-disabled {
            color: #bbb;
          }

          .swiper-button-next,
          .swiper-button-prev {
            color: #aaa;
          }

          .swiper-pagination-bullet-active {
            background-color: #ffcc29;
          }

          .swiper-item {
            padding: 0 1rem;
          }

          h3 {
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            margin-bottom: 1rem;
          }
          .oath {
            display: flex;
            align-items: start;
            padding: 4rem 10rem 1rem;
            column-gap: 3rem;

            @media (max-width: 1366px) {
              padding: 4rem 5rem 1rem 3rem;
            }

            @media (max-width: 1279px) {
              flex-direction: column;
              align-items: center;
              gap: 1rem;
            }

            @media (max-width: 991px) {
              padding: 4rem 0;
            }

            // ol {
            //   display: flex;
            //   flex-direction: column;
            //   flex-wrap: wrap;
            //   height: 300px;
            //   column-gap: 4rem;
            //   row-gap: 1rem;
            // }

            li {
              font-size: 16px;
              color: #4d4d4d;

              &::marker {
                font-weight: bold;
              }

              p {
                margin-bottom: 0.25rem;
              }

              .jp {
                font-style: italic;
                font-weight: 600;
                font-size: 18px;
              }

              .en {
                font-style: italic;
                font-weight: 500;
                font-size: 14px;
                color: #939393;
              }

              .id {
                font-weight: 500;
                font-size: 14px;
                color: #4d4d4d;
              }
            }
          }

          .philosophy {
            max-width: 890px;
            margin: 2rem auto;
            p {
              font-style: italic;
              font-weight: 500;
              font-size: 18px;
              color: #4d4d4d;
            }
          }

          .style {
            display: flex;
            justify-content: center;
            column-gap: 4rem;
            margin: 3rem 0;

            @media (max-width: 991px) {
              column-gap: 1rem;
            }

            .symbols {
              display: flex;
              gap: 1rem;
              width: 276px;
              flex-wrap: wrap;

              .symbol {
                filter: brightness(0.5);
                border: 1px solid #c4c4c4;
                border-radius: 10px;
                width: 130px;
                height: 130px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s;
                cursor: pointer;

                &:hover,
                &.active {
                  background-color: black;
                  filter: brightness(1);
                }
              }
            }

            .description {
              h3 {
                font-weight: 700;
                font-size: 32px;
              }

              p {
                font-size: 16px;
                color: #4d4d4d;
                max-width: 660px;
              }
            }
          }
        }
      }

      &.news {
        background-image: url('../../public/assets/images/bg-event.jpg');
        position: relative;

        h2 {
          position: relative;
          z-index: 1;
        }

        .news-thumbnails {
          position: relative;
          z-index: 1;
          height: 504px;
          column-gap: 1rem;
          row-gap: 0.5rem;
          display: grid;
          // grid-template-columns:
          //   calc(25% - 0.5rem) calc(25% - 1em) calc(25% - 1rem)
          //   calc(25% - 0.5rem);
          grid-template-columns: calc(25% - 0.5rem) calc(25% - 1em) calc(25% - 1rem);
          grid-template-rows: auto;
          grid-template-areas:
            'first first second second'
            'first first third third';

          @media (max-width: 991px) {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 1.5rem;
          }

          .news-thumbnail {
            text-decoration: none;
            border-radius: 9px;
            display: flex;
            align-items: flex-end;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @media (max-width: 991px) {
              height: 480px;
              background-repeat: no-repeat;
              background-size: cover;
            }

            @media (max-width: 767px) {
              height: 360px;
            }

            div {
              width: 100%;
              color: #fff;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.567708) 26.56%, #000000 100%);
              padding: 1.5rem 1rem;
              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;
              p {
                font-size: 16px;
              }

              h6 {
                font-weight: 700;
                font-size: 24px;
              }
            }

            &:first-of-type {
              grid-area: first;
              // background-image: url('../../public/assets/images/news1st.png');
            }
            &:nth-of-type(2) {
              grid-area: second;
              // background-image: url('../../public/assets/images/news2nd.png');
            }
            &:nth-of-type(3) {
              grid-area: third;
              // background-image: url('../../public/assets/images/news3rd.png');

              p {
                font-size: 12px;
              }

              h6 {
                font-size: 16px;
              }
            }
            // &:last-of-type {
            //   grid-area: fourth;
            //   // background-image: url('../../public/assets/images/news4th.png');

            //   p {
            //     font-size: 12px;
            //   }

            //   h6 {
            //     font-size: 16px;
            //   }
            // }
          }
        }

        .link {
          position: relative;
          z-index: 1;
          text-align: center;
          padding: 2rem 0 1rem;
        }

        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #f5f2e6, $alpha: 0.8);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.videos {
        .link {
          position: relative;
          z-index: 1;
          text-align: center;
          padding: 2rem 0 1rem;
        }

        ul {
          list-style-type: none;
          padding-left: 0;
          display: flex;
          justify-content: center;
          column-gap: 1rem;

          @media (max-width: 991px) {
            flex-direction: column;
            gap: 1.5rem;

            iframe {
              height: 360px;
            }
          }

          li {
            flex-basis: 25%;

            p {
              a {
                font-weight: 600;
                font-size: 16px;
                color: #4d4d4d;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  // ABOUT
  .about {
    section {
      &.videos {
        iframe {
          @media (max-width: 1279px) {
            width: 90%;
            height: 360px;
          }
        }
      }
      &.org-structure {
        background-image: url('../../public/assets/images/bg-event.jpg');
        position: relative;

        hr {
          width: 80%;
          margin: 2rem auto;
        }

        .slick-slider {
          margin: 3rem 0;
        }

        .slick-prev {
          left: -40px;
          z-index: 1;

          @media (max-width: 767px) {
            display: none;
          }
        }

        .slick-next {
          right: -40px;
          z-index: 1;

          @media (max-width: 767px) {
            display: none;
          }
        }

        .slick-prev:before,
        .slick-next:before {
          display: none;
        }

        h2 {
          position: relative;
          z-index: 1;
        }

        & > div {
          position: relative;
          z-index: 1;
          // max-width: 1175px;
          margin: 3rem auto;

          @media (max-width: 767px) {
            margin: 0 auto;
            padding: 0;
          }

          .item {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            text-align: center;

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              object-fit: cover;
            }

            @media (max-width: 767px) {
              padding: 0 1rem;
            }

            h6 {
              margin-bottom: 0;
              font-weight: 600;
              font-size: 16px;

              @media (max-width: 767px) {
                font-size: 12px;
                text-align: center;
              }
            }

            p {
              margin-bottom: 0;
              font-weight: 500;
              font-size: 14px;
              color: #4d4d4d;
              padding: 0.5rem;

              @media (max-width: 767px) {
                font-size: 11px;
                text-align: center;
              }
            }
          }
        }

        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #f5f2e6, $alpha: 0.8);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.logo-meaning {
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 3rem;

          @media (max-width: 767px) {
            flex-direction: column;
          }

          & > div {
            @media (max-width: 767px) {
              width: 90%;
            }
          }

          h6 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 0;
          }

          p {
            max-width: 700px;
            font-weight: 500;
            font-size: 14px;
            color: #4d4d4d;
          }
        }
      }

      &.visi-misi {
        background-image: url('../../public/assets/images/bg-event.jpg');
        position: relative;

        h2 {
          position: relative;
          z-index: 1;
        }

        & > div {
          position: relative;
          z-index: 1;
          text-align: center;
          max-width: 827px;
          margin: 3rem auto 0;

          @media (max-width: 767px) {
            width: 90%;
          }

          h6 {
            font-weight: 600;
            font-size: 20px;
          }

          p {
            font-weight: 500;
            font-size: 16px;
            color: #4d4d4d;
          }
        }

        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #f5f2e6, $alpha: 0.8);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.mars {
        iframe {
          @media (max-width: 1279px) {
            width: 90%;
            height: 360px;
          }
        }
        .mars-text {
          p {
            font-style: italic;
            font-size: 20px;
            color: #4d4d4d;
            width: 315px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  // ACTIVITIES
  .activities {
    .container {
      display: flex;
      justify-content: center;
      column-gap: 2rem;
      margin-top: 3rem;

      @media (max-width: 1279px) {
        flex-direction: column;
      }
    }

    .month {
      display: flex;
      column-gap: 2rem;
      align-items: flex-start;
      margin-bottom: 1.5rem;

      @media (max-width: 1279px) {
        justify-content: center;
      }

      @media (max-width: 575px) {
        flex-direction: column;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 24px;
      width: 150px;
      text-align: right;

      @media (max-width: 575px) {
        text-align: left;
      }

      & + div > div {
        margin-bottom: 1rem;

        display: flex;
        column-gap: 3rem;

        @media (max-width: 575px) {
          column-gap: 1rem;
        }
      }
    }

    h4 + div {
      width: 420px;

      @media (max-width: 575px) {
        width: 100%;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      color: #4d4d4d;
      margin-bottom: 0;

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }

    a[href] {
      font-weight: 700;
      font-size: 16px;
      color: #806615;
      max-width: 240px;

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }

    a {
      font-weight: 700;
      font-size: 16px;
      max-width: 240px;

      @media (max-width: 575px) {
        font-size: 14px;
      }
    }
  }

  // NEWS LIST
  .news-list {
    section {
      position: relative;
    }

    select.order {
      width: 170px;
      position: absolute;
      top: 50px;
      right: 7rem;

      @media (max-width: 767px) {
        position: static;
        margin: 1rem auto;
      }
    }

    .news {
      display: flex;
      column-gap: 2rem;
      margin-bottom: 3rem;

      & > img {
        width: 337px;
      }

      & > div {
        max-width: 720px;
      }

      @media (max-width: 1024px) {
        flex-direction: column;
        row-gap: 1rem;
      }

      a {
        text-decoration: none;
        font-size: 16px;
        color: #ed3237;
        font-weight: 600;
      }
    }

    img {
      border-radius: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    .date {
      font-size: 12px;
      color: #888888;
    }

    .content {
      font-size: 16px;
      color: #4d4d4d;
      width: 700px;

      @media (max-width: 1024px) {
        flex-direction: column;
        width: auto;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
    }
  }

  .pic {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    &-card {
      background-color: #fff;
      border-radius: 5px;
      padding: 1.5rem;

      .item {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        p {
          margin-bottom: 5px;
          color: #939393;
        }

        img {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }

  // DOJO LIST
  .dojo {
    .btn-danger {
      width: 161px;
      height: 39px;
      padding: 0;

      @media (max-width: 767px) {
        width: 100%;
      }

      &.fit {
        width: auto;
        height: auto;
        padding: 0.25rem 0.5rem;
      }
    }

    .table-wrapper {
      overflow-x: auto;
      margin-bottom: 1rem;
    }

    .table {
      min-width: 1200px;
      @media (max-width: 991px) {
        min-width: auto;
      }
      margin-bottom: 0;
      box-shadow: 0px 4px 10px 0px #00000026;
    }

    .table-striped tr:nth-child(odd) td {
      --bs-table-accent-bg: #fff;
    }

    .table-striped tr:nth-child(even) td {
      background-color: #fdfbf4;
      @media (max-width: 991px) {
        background-color: #fff;
      }
    }

    thead {
      @media (max-width: 991px) {
        display: none;
      }
    }

    th {
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      background-color: #806615;
      padding: 1rem 1.5rem;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }

    tr {
      @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        border-top: solid 12px #806615;
        border-radius: 8px;
        margin-bottom: 1rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 97%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    td {
      font-family: 'Open Sans';
      font-size: 14px;
      color: #4d4d4d;
      border: none;
      padding: 1rem 1.5rem;

      & > div {
        @media (max-width: 991px) {
          width: calc(100% - 150px);
        }
      }

      @media (max-width: 991px) {
        display: flex;
        font-size: 12px;
      }

      label {
        display: none;
        font-size: 12px;
        color: #7a7a7a;
        @media (max-width: 991px) {
          display: inline-block;
          width: 150px;
        }
      }

      &:last-child {
        a {
          display: flex;
          gap: 1rem;
        }
      }

      a,
      span {
        text-decoration: none;
        font-style: italic;
        font-weight: 600;
        color: #3b8eef;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // MEMBER
  .member,
  .merchandise {
    .btn-danger {
      width: 161px;
      height: 39px;
      padding: 0;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .filter {
      display: flex;
      gap: 1rem;

      @media (max-width: 991px) {
        .alphabet {
          display: flex;
          flex-wrap: wrap;
        }
      }

      & > span {
        font-size: 16px;
        text-decoration: none;
      }

      span.active {
        color: #9e2125 !important;
      }

      .alphabet span {
        font-size: 16px;
        color: #939393;
        text-decoration: none;
        margin-right: 1rem;
      }
    }

    .member-list {
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 1rem;
      margin: 1rem 0;

      @media (max-width: 1439px) {
        grid-template-columns: auto auto auto;
      }

      @media (max-width: 1080px) {
        grid-template-columns: auto auto;
      }

      @media (max-width: 991px) {
        grid-template-columns: auto;
      }

      &.merch-list {
        grid-template-columns: auto auto auto;

        img {
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.1);
          }
        }

        h5 {
          transition: all 0.2s;

          &:hover {
            color: #ed3237;
          }

          span {
            cursor: pointer;
          }
        }

        @media (max-width: 1439px) {
          grid-template-columns: auto auto;
        }

        @media (max-width: 991px) {
          grid-template-columns: auto;
        }

        .member-list-item {
          min-width: 420px;

          @media (max-width: 575px) {
            min-width: 320px;
          }
        }

        b {
          color: #000;
        }
      }

      &-item {
        box-shadow: 0px 0px 10px 0px #00000026;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        column-gap: 2rem;
        min-width: 320px;
        // transition: all 0.2s;
        // cursor: pointer;

        // &:hover {
        //   box-shadow: 0px 0px 16px 0px #00000036;
        // }

        & > img {
          border-radius: 50%;
          width: 80px;
          height: 80px;
          object-fit: cover;
        }

        h5 {
          font-weight: 600;
          font-size: 16px;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          color: #939393;
          margin-bottom: 0;
        }
      }
    }

    .categories {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
      flex-wrap: wrap;

      max-height: 140px;
      overflow-y: auto;

      .btn-pill {
        border: 1px solid #828282;
        border-radius: 1rem;
        padding: 8px 16px;
        height: 40px;
        background-color: #fff;
        color: #939393;

        &.active {
          background-color: rgba($color: #ed3237, $alpha: 0.25);
          border: 1px solid #ed3237;
          color: #ed3237;
        }
      }
    }
  }

  // CONTACT
  .contact {
    .branches {
      display: grid;
      grid-template-columns: auto auto auto;
      column-gap: 1rem;
      row-gap: 3rem;
      margin: 1rem 0;

      @media (max-width: 1024px) {
        grid-template-columns: auto auto;
      }

      @media (max-width: 575px) {
        grid-template-columns: auto;
      }

      .branch {
        h3 {
          font-weight: 700;
          font-size: 24px;
          color: #4d4d4d;
        }

        .subtitle {
          font-weight: 600;
          font-size: 16px;
          color: #4d4d4d;
        }

        .address {
          font-weight: 400;
          font-size: 16px;
          color: #4d4d4d;
        }

        a {
          font-weight: 600;
          font-size: 16px;
          color: #ed3237;
          text-decoration: none;
        }

        ul {
          padding: 0;
          list-style-type: none;

          li {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            span {
              font-weight: 600;
              font-size: 16px;
              color: #4d4d4d;
            }
          }
        }
      }
    }

    .contact-form {
      margin-top: 3rem;

      h2 {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 0;
      }

      & > p {
        font-size: 16px;
        text-align: center;
        color: #4d4d4d;
      }

      form {
        width: 640px;
        margin: auto;

        @media (max-width: 1024px) {
          width: 100%;
        }

        .form-group {
          margin-bottom: 1rem;
        }

        button {
          width: 640px;
          height: 51px;
          background: linear-gradient(89.87deg, #ed3237 0.98%, #d71b20 98.72%);
          border-radius: 6px;

          @media (max-width: 1024px) {
            width: 100%;
          }
        }
      }
    }
  }

  .news-detail {
    .breadcrumb {
      font-weight: 400;
      color: #888888;
      a {
        text-decoration: none;
        font-weight: 700;
        font-size: 16px;
        color: #b11f24;
      }
    }
    h2 {
      text-align: left;
    }

    .content {
      p {
        font-size: 16px;
        color: #4d4d4d;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 28px;
    }

    .news-thumbnails {
      li > div {
        max-width: 265px;
        height: 160px;
        border-radius: 10px;
        margin-bottom: 1rem;
        background-size: cover;

        @media (max-width: 767px) {
          max-width: 100%;
        }
      }
    }

    .link {
      position: relative;
      z-index: 1;
      text-align: center;
      padding: 2rem 0 1rem;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      display: flex;
      column-gap: 1rem;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      li {
        flex-basis: 25%;

        p a {
          color: #000;
          text-decoration: none;
          margin-top: 1rem;
          font-weight: 700;
          font-size: 20px;
        }
      }
    }
  }

  .banner {
    .banner-item {
      // background-image: url('../../public/assets/images/slide-img.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 440px;
      color: #fff;
      position: relative;
      display: flex !important;
      align-items: center;

      @media (max-width: 575px) {
        height: 250px;
      }

      .container {
        position: relative;
        z-index: 1;
        padding: 0 4rem;

        @media (max-width: 575px) {
          padding: 0 1rem;
          width: 90%;
        }

        h2 {
          font-weight: 700;
          font-size: 32px;

          @media (max-width: 575px) {
            font-size: 22px;
          }
        }

        p {
          max-width: 545px;
          border-left: solid 4px #ffcc29;
          padding-left: 1rem;
          font-weight: 500;
          font-size: 16px;
        }
      }

      &::after {
        content: '';
        width: 100%;
        height: 440px;
        background-color: rgba($color: #000000, $alpha: 0.4);
        position: absolute;
        top: 0;

        @media (max-width: 575px) {
          height: 250px;
        }
      }
    }

    .slick-prev {
      left: 55px;
      z-index: 1;

      @media (max-width: 575px) {
        display: none;
      }
    }

    .slick-next {
      right: 55px;
      z-index: 1;

      @media (max-width: 575px) {
        display: none;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      display: none;
    }

    .slick-dots {
      bottom: 1rem;

      li button:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        opacity: 1;
      }

      li.slick-active {
        button::before {
          background-color: #ffcc29;
        }
      }
    }
  }

  .oath {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: transparent;
      width: auto;
      height: auto;
      border-radius: unset;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: rgba(0, 0, 0, 0.6);
    width: 80px;
    height: 80px;
    border-radius: 50%;

    @media (max-width: 575px) {
      width: 40px;
      height: 40px;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  .slick-disabled {
    display: none !important;
  }

  .swiper-slide > img {
    height: 420px;
    object-fit: cover;
  }

  .thumbnail .swiper-slide > img {
    height: 140px;
    object-fit: cover;
  }

  .jabatan-org p {
    padding: 0 !important;
  }
}
